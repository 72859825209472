import React from 'react';
import { getImage } from 'gatsby-plugin-image';
import {
    Container,
    Heading,
    Box,
    Flex,
    Text
} from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import NewsCard from './NewsCard'
import { ChevronRightIcon } from '@chakra-ui/icons';

export default function NewsCards({ newsItem }) {

    return (
        <Container maxW="container.xl" as="section" id="news" mb="16">
            <Box>
            <Flex flexWrap="wrap" justifyContent="flex-start">
                    {newsItem.map((item, index) => (
                        <NewsCard 
                            key={index}    
                            title={item.node.data.title.text}
                            exceprt={item.node.data.exceprt}
                            featured_image={getImage(item.node.data.featured_image.localFile)}
                            uid={item.node.uid}
                            date={item.node.last_publication_date}
                        />
                    ))}
                </Flex>
            </Box>
            <Box textAlign="center" mt="2">
          <Text fontSize="2xl" fontWeight="700">
              <GatsbyLink to="/news">More TREC &amp; Geo News <ChevronRightIcon /></GatsbyLink>
          </Text>
      </Box>
        </Container>
    )
}