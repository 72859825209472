import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
    Heading,
    Box,
    Flex,
    Text,
    LinkBox,
    LinkOverlay
} from '@chakra-ui/react';
import { motion } from 'framer-motion'
import { Link as GatsbyLink } from 'gatsby';
import { ChevronRightIcon } from '@chakra-ui/icons';

export default function NewsCard(props) {
    return (
        <LinkBox p="4" w={{base: '100%', md: '50%', lg: '33.33%'}}
            as={motion.div}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            layout 
            initial={{opacity: 0}}
            animate={{ opacity: 1}}
            exit={{ opacity: 0}}
            cursor="pointer"
    >
        <LinkOverlay as={GatsbyLink} to={`/news/${props.uid}`} >
        <Flex flexDirection="column" justifyContent="flex-start" w="100%" h="100%" 
            border="1px solid" borderColor="gray.200" borderRadius="xl"
            boxShadow="lg"
            >
            <Box bg="sage.500" p="4" borderTopRadius="xl">
                <Heading as="h3" size="md" fontWeight="bold" color="white">
                    {props.title}
                </Heading>
            </Box>
            <Box>
                {props.featured_image && (
                    <GatsbyImage image={props?.featured_image} alt={props.featured_image?.alt} />
                )
                }
            </Box>
            <Box p="4">
                <Text mb="0" size="sm">
                        {props.exceprt}
                    </Text>
            </Box>
            <Flex w="100%" p="4" justifyContent="space-between" marginTop="auto">
                <Box>
                    <Text size="sm" color="gray.500" mb="0">
                        {props.date}
                    </Text>
                </Box>
                <Box>
                    <Text textAlign="right" mb="0" color="bold-green.600">
                        Read More <ChevronRightIcon />
                    </Text>
                </Box>
                {/* <Link as={GatsbyLink} to={`/news/${props.uid}`} fontWeight="700" textAlign="right" color="bold-green.600" _hover={{color: 'sage.500', textDecoration: 'none'}}>
                    Read More
                </Link> */}
            </Flex>
               
        </Flex>
        </LinkOverlay>
        </LinkBox>
    )
}